.header {
  @include grid-row();
  padding-top: rem-calc(20);
  padding-bottom: rem-calc(20);


    .menu {
              @include breakpoint(small) {
              @include grid-column(12);
              font-size: rem-calc(16);
              display: block;
              padding-top: rem-calc(30);
                  a {
                    color: $menulink;
                    }
                  img {
                      min-height: rem-calc(30);
                      min-width: rem-calc(30);
                  }
              }

              @include breakpoint(medium) {
              @include grid-column(12);
              font-size: rem-calc(24);
              display: block;
              }
      }
}


//.fade {
//   opacity: 1;
//   transition: opacity .25s ease-in-out;
//   -moz-transition: opacity .25s ease-in-out;
//   -webkit-transition: opacity .25s ease-in-out;
//   }

//   .fade:hover {
//      opacity: 0.5;
//      }



.front-cases-headline-promo {
  padding-top: rem-calc(10);
  padding-bottom: rem-calc(10);

  @include grid-row();

  .front-cases-headline-element {

    @include grid-column(12);
    position: relative;
    margin-bottom: rem-calc(20);


      img {
        min-height: rem-calc(300);
        object-fit: cover;
        background-color: $cases;
      }

          .image-overlay {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 75%;
          padding-left: rem-calc(40);
          padding-bottom: rem-calc(20);

              h2 {
                color: $bannertext;
                padding-right: rem-calc(20);
                @include breakpoint(small) { font-size: rem-calc(24); }
                @include breakpoint(medium) { font-size: rem-calc(32); }
                @include breakpoint(large) { font-size: rem-calc(38); }

                    span.highlight {
                    background-color: $cases;
                    }


                }

              h3 {
                color: $bannertext;
                padding-right: rem-calc(20);
                @include breakpoint(small) { font-size: rem-calc(18); }
                @include breakpoint(medium) { font-size: rem-calc(22); }
                @include breakpoint(large) { font-size: rem-calc(28); }

                span.highlight {
                background-color: $cases;
                }

              }

          }

    }
}


.front-blog-section-promo {
  @include grid-row();

  .front-blog-section-element {

      @include grid-column(12);
      position: relative;
      margin-bottom: rem-calc(20);


      @include breakpoint(medium) {
      @include grid-column(6);
      position: relative;
      margin-bottom: rem-calc(20);
    }

    img {
      min-height: rem-calc(250);
      object-fit: cover;
      background-color: $blog;
    }


          .image-overlay {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 75%;
            padding-left: rem-calc(40);
            padding-bottom: rem-calc(20);

                  h3 {
                    color: $bannertext;
                    padding-right: rem-calc(20);
                    @include breakpoint(small) { font-size: rem-calc(24); }
                    @include breakpoint(medium) { font-size: rem-calc(28); }
                    @include breakpoint(large) { font-size: rem-calc(34); }

                        span.highlight {
                        background-color: $blog;
                        }

                    }

                  h4 {
                  color: $bannertext;
                  padding-right: rem-calc(20);
                  @include breakpoint(small) { font-size: rem-calc(18); }
                  @include breakpoint(medium) { font-size: rem-calc(22); }
                  @include breakpoint(large) { font-size: rem-calc(28); }

                  span.highlight {
                  background-color: $blog;
                  }


                }
            }
    }

    .front-method-section-element {

        @include grid-column(12);
        position: relative;
        margin-bottom: rem-calc(20);

        img {
          min-height: rem-calc(250);
          object-fit: cover;
          background-color: $method;
          }


        @include breakpoint(medium) {
        @include grid-column(6);
        position: relative;
        margin-bottom: rem-calc(20);
      }


            .image-overlay {
              position: absolute;
              bottom: 0;
              left: 0;
              width: 75%;
              padding-left: rem-calc(40);
              padding-bottom: rem-calc(20);

                    h3 {
                      color: $bannertext;
                      padding-right: rem-calc(20);
                      @include breakpoint(small) { font-size: rem-calc(24); }
                      @include breakpoint(medium) { font-size: rem-calc(28); }
                      @include breakpoint(large) { font-size: rem-calc(34); }

                          span.highlight {
                          background-color: $method;
                          }

                      }

                    h4 {
                    color: $bannertext;
                    padding-right: rem-calc(20);
                    @include breakpoint(small) { font-size: rem-calc(18); }
                    @include breakpoint(medium) { font-size: rem-calc(22); }
                    @include breakpoint(large) { font-size: rem-calc(28); }

                    span.highlight {
                    background-color: $method;
                    }


                  }
              }
      }


}


.cases-section-promo {
  @include grid-row();

  .cases-section-element {

      @include grid-column(12);
      position: relative;
      margin-bottom: rem-calc(20);

      img {
        background-color: $cases;
      }


      @include breakpoint(medium) {
      @include grid-column(6);
      position: relative;
      margin-bottom: rem-calc(20);
    }


          .image-overlay {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 75%;
            padding-left: rem-calc(40);
            padding-bottom: rem-calc(20);

                  h3 {
                    color: $bannertext;
                    padding-right: rem-calc(20);
                    @include breakpoint(small) { font-size: rem-calc(20); }
                    @include breakpoint(medium) { font-size: rem-calc(22); }
                    @include breakpoint(large) { font-size: rem-calc(24); }

                        span.highlight {
                        background-color: $cases;
                        }

                    }

                  h4 {
                  color: $bannertext;
                  padding-right: rem-calc(20);
                  @include breakpoint(small) { font-size: rem-calc(14); }
                  @include breakpoint(medium) { font-size: rem-calc(16); }
                  @include breakpoint(large) { font-size: rem-calc(18); }

                      span.highlight {
                      background-color: $cases;
                      }


                }
          }
    }
}



.method-section-promo {
  @include grid-row();

  .method-section-element {

      @include grid-column(12);
      position: relative;
      margin-bottom: rem-calc(20);

      img {
      background-color: $method;
      }

      @include breakpoint(medium) {
      @include grid-column(6);
      position: relative;
      margin-bottom: rem-calc(20);
    }


          .image-overlay {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 75%;
            padding-left: rem-calc(40);
            padding-bottom: rem-calc(20);

                  h3 {
                    color: $bannertext;
                    padding-right: rem-calc(20);
                    @include breakpoint(small) { font-size: rem-calc(20); }
                    @include breakpoint(medium) { font-size: rem-calc(22); }
                    @include breakpoint(large) { font-size: rem-calc(24); }

                        span.highlight {
                        background-color: $method;
                        }

                    }

                  h4 {
                  color: $bannertext;
                  padding-right: rem-calc(20);
                  @include breakpoint(small) { font-size: rem-calc(14); }
                  @include breakpoint(medium) { font-size: rem-calc(16); }
                  @include breakpoint(large) { font-size: rem-calc(18); }

                  span.highlight {
                  background-color: $method;
                  }

                }
            }
    }

}


.blog-section-promo {
  @include grid-row();

  .blog-section-element {

      @include grid-column(12);
      position: relative;
      margin-bottom: rem-calc(20);

      img {
        background-color: $blog;
      }


      @include breakpoint(medium) {
      @include grid-column(6);
      position: relative;
      margin-bottom: rem-calc(20);
    }


          .image-overlay {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 75%;
            padding-left: rem-calc(40);
            padding-bottom: rem-calc(20);

                  h3 {
                    color: $bannertext;
                    padding-right: rem-calc(20);
                    @include breakpoint(small) { font-size: rem-calc(20); }
                    @include breakpoint(medium) { font-size: rem-calc(22); }
                    @include breakpoint(large) { font-size: rem-calc(24); }

                        span.highlight {
                        background-color: $blog;
                        }

                    }

                  h4 {
                  color: $bannertext;
                  padding-right: rem-calc(20);
                  @include breakpoint(small) { font-size: rem-calc(14); }
                  @include breakpoint(medium) { font-size: rem-calc(16); }
                  @include breakpoint(large) { font-size: rem-calc(18); }

                  span.highlight {
                  background-color: $blog;
                  }


                }
            }
    }
}



.article-wrapper {
  @include grid-row();
  padding-top: rem-calc(20);
  padding-bottom: rem-calc(20);

      h1 {
        color: $pagetitle;
        margin-left: rem-calc(20);
        margin-right: rem-calc(20);
          @include breakpoint(small) { font-size: rem-calc(36); }
          @include breakpoint(medium) { font-size: rem-calc(48); }
          @include breakpoint(large) { font-size: rem-calc(72); }
          @include grid-column(11);
          }

      h2 {

        color: $summary;
        margin-bottom: rem-calc(30);
        margin-left: rem-calc(20);
        margin-right: rem-calc(20);
          @include breakpoint(small) { font-size: rem-calc(24); }
          @include breakpoint(medium) { font-size: rem-calc(28); }
          @include breakpoint(large) { font-size: rem-calc(36); }
          @include grid-column(11);
          }



      h5 {

          color: $summary;
          margin-left: rem-calc(20);
          margin-right: rem-calc(20);
          margin-bottom: rem-calc(10);
          @include breakpoint(small) { font-size: rem-calc(14); }
          @include breakpoint(medium) { font-size: rem-calc(18); }
          @include breakpoint(large) { font-size: rem-calc(22); }
          @include grid-column(11);

        }



.article-text {

  margin-left: rem-calc(20);
  margin-right: rem-calc(20);

              h3 {

                color: $bodytext;
                  @include breakpoint(small) { font-size: rem-calc(24); }
                  @include breakpoint(medium) { font-size: rem-calc(28); }
                  @include breakpoint(large) { font-size: rem-calc(36); }
                  @include grid-column(10);
                  }

                 p {
                  color: $bodytext;
                  @include breakpoint(small) { font-size: rem-calc(16); }
                  @include breakpoint(medium) { font-size: rem-calc(20); }
                  @include breakpoint(large) { font-size: rem-calc(24); }
                  @include grid-column(10);
                  }
        }




.article-image {
//@include grid-row();


  .article-image-element {
      @include grid-column(12);
      position: relative;
      margin-bottom: rem-calc(20);


          img {
            min-height: rem-calc(300);
            object-fit: cover;
            border-top: 1px solid $lines;
            border-bottom: 1px solid $lines;
            padding-top: rem-calc(20);
            padding-bottom: rem-calc(20);
            margin-top: rem-calc(10);
            margin-bottom: rem-calc(10);
            }

              .article-image-overlay {
              position: absolute;
              bottom: 0;
              left: 0;
              width: 90%;
              padding-left: rem-calc(10);
              padding-right: rem-calc(20);


                  h2 {
                    color: $bannertext;
                    @include breakpoint(small) { font-size: rem-calc(16); }
                    @include breakpoint(medium) { font-size: rem-calc(20); }
                    @include breakpoint(large) { font-size: rem-calc(24); }

                        span.highlight {
                            background-color: $caption;
                            font-style: italic;
                        }

                    }

                }
          }
    }
}



.footer {
  @include grid-row();
  padding-top: rem-calc(50);
  padding-bottom: rem-calc(30);
  text-align: center;


      .footer-contact-element {

          @include breakpoint(small)
          @include grid-column(12);
          margin-bottom: rem-calc(20);
              h4 {
                color: $menulink;
                font-size: rem-calc(16);
              }


          @include grid-row();
          @include breakpoint(medium)
          @include grid-column(12);
          margin-bottom: rem-calc(20);
              h4 {
                color: $menulink;
                font-size: rem-calc(20);
              }

      }



    .footer-social-element {


          @include breakpoint(small)
          @include grid-column(12);
          margin-bottom: rem-calc(16);

          img {
            background-color: $link;
          }


          @include grid-row();
          @include breakpoint(medium)
          @include grid-column(12);
          margin-bottom: rem-calc(18);
      }
}
